import { useContext } from "react";
import { UserContext } from "../../App";
import { useAuth } from "react-oidc-context";
import {
    Box,
    CircularProgress,
    LinearProgress,
    Typography,
} from "@mui/material";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
    children: React.ReactNode;
    representativeOnly?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
    children,
    representativeOnly = false,
}) => {
    const userContext = useContext(UserContext);
    const auth = useAuth();

    switch (auth.activeNavigator) {
        case "signinSilent":
            return (
                <Box>
                    <Typography>Connexion...</Typography>
                    <LinearProgress />
                </Box>
            );
        case "signoutRedirect":
            return (
                <Box>
                    <Typography>Déconnexion...</Typography>
                    <LinearProgress />
                </Box>
            );
        default: {
          
        }
    }

    if (auth.isLoading) {
        return (
            <Box>
                <CircularProgress />
            </Box>
        );
    }

    if (auth.error) {
        return (
            <Typography>
                Une erreur est survenue: {auth.error.message}
            </Typography>
        );
    }

    if (representativeOnly && !userContext.isRepresentative)
        return <Navigate to="/" />;

    return <>{children}</>;
};

export default ProtectedRoute;
