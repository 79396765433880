import { Box, Paper, Typography, styled } from "@mui/material";
import { useAuth } from "react-oidc-context";
import Products from "../Products/Products";

const Tab = styled(Paper)({
    padding: 12,
    fontSize: "large",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
});
Tab.defaultProps = {
    elevation: 5,
};

const CustomerHome = () => {
    const auth = useAuth();
    return (
        <Box display="flex" flexGrow={1} flexDirection="column">
            <Box
                component="img"
                sx={{
                    maxWidth: { lg: "25%" },
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
                src="/images/logo.png"
            />
            <Typography align="center" variant="h5" mb={4}>
                Bienvenue, {auth.user?.profile.given_name}
            </Typography>
            <Typography align="center" variant="h6" mb={2}>
                Nos produits
            </Typography>
            <Products />
        </Box>
    );
};

export default CustomerHome;
