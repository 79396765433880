import { Box } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../../../App";
import CustomerHome from "./Customer/CustomerHome";
import RepresentativeHome from "./Representative/RepresentativeHome";
import Header from "../../core/Header";

const Home = () => {
    const userContext = useContext(UserContext);
    return (
        <Box display="flex" flexGrow={1} flexDirection="column">
            <Header
                title={
                    !userContext.isRepresentative
                        ? "Menu client"
                        : "Menu représentant"
                }
            />
            {!userContext.isRepresentative ? (
                <CustomerHome />
            ) : (
                <RepresentativeHome />
            )}
        </Box>
    );
};

export default Home;
