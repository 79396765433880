import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Typography,
    styled,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../../core/Header";
import { Product } from "../../../../interfaces/ProductInterfaces";

const ProductInfo = styled(Card)(({ theme }) => ({
    width: "85%",
    margin: "auto",
    [theme.breakpoints.up("lg")]: {
        width: "40%",
    },
}));
ProductInfo.defaultProps = {
    elevation: 5,
};

const ProductDetails = () => {
    const { product }: { product: Product; } = useLocation().state as { product: Product; };
    const navigate = useNavigate();

    return (
        <Box display="flex" flexGrow={1} flexDirection="column">
            <Header
                title="Détails du produit"
                onBackButtonClick={() => navigate("/")}
            />
            <Typography align="center" variant="h4" mb={2}>
                {product.name}
            </Typography>
            <Box
                component="img"
                sx={{
                    maxWidth: { lg: "40%", xs: "90%" },
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
                src={product.image}
            />
            <Box display="flex" flexDirection="column" gap={3} mt={2} mb={2}>
                <ProductInfo>
                    <CardHeader title="Détails" />
                    <CardContent>{product.details}</CardContent>
                </ProductInfo>
                <ProductInfo>
                    <CardHeader title="Fonctions" />
                    <CardContent>Fonctions du produit</CardContent>
                </ProductInfo>
                <ProductInfo>
                    <CardHeader title="Prix" />
                    <CardContent>Prix du produit</CardContent>
                </ProductInfo>
            </Box>
        </Box>
    );
};

export default ProductDetails;
