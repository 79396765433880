import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#112234",
        },
        secondary: {
            main: "#B5EF8A",
        },
    },
    typography: {
        fontFamily: ["Poppins"].join(","),
    },
    components: {
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    color: "lightgray",
                    "&.Mui-selected": {
                        color: "white",
                    },
                },
            },
        },
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    backgroundColor: "#112234",
                },
            },
        },
    },
});
