import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ChatIcon from "@mui/icons-material/Chat";
import PeopleIcon from "@mui/icons-material/People";
import { Link, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";
import { useAuth } from "react-oidc-context";

const BottomNavigator = () => {
    const location = useLocation();
    const [page, setPage] = useState(location.pathname);
    const userContext = useContext(UserContext);
    const auth = useAuth();

    useEffect(() => {
        setPage(location.pathname);
    }, [location]);

    let navigationItems = [];

    if (auth.isAuthenticated) {
        navigationItems.push(
            <BottomNavigationAction
                key="home"
                value="/"
                component={Link}
                to="/"
                label="Accueil"
                icon={<HomeIcon />}
            />
        );

        if (!userContext.isRepresentative) {
            navigationItems.push(
                <BottomNavigationAction
                    key="chat"
                    value="/chat"
                    component={Link}
                    to="/chat"
                    label="Chat"
                    icon={<ChatIcon />}
                />
            );
        } else {
            navigationItems.push(
                <BottomNavigationAction
                    key="customers"
                    value="/customers"
                    component={Link}
                    to="/customers"
                    label="Clients"
                    icon={<PeopleIcon />}
                />
            );
        }
    }

    return (
        <Paper
            sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: "primary.main",
                borderRadius: 0,
            }}
            variant="outlined"
        >
            <BottomNavigation
                showLabels
                sx={{ width: "100vw" }}
                value={page}
                onChange={(_, newPage) => {
                    setPage(newPage);
                }}
            >
                {navigationItems}
            </BottomNavigation>
        </Paper>
    );
};

export default BottomNavigator;
