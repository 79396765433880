import axios from "axios";
import { Conversation, CreateMessageDTO, Message } from "../interfaces/ChatInterface";

export const sendMessage = async (message: CreateMessageDTO) : Promise<Message> => {
    const response = await axios.post("/message/create", JSON.stringify(message), {
        headers:{
            "Content-Type": "application/json; charset=UTF-8",
        }
    });

    return response.data as Message;
};

export const fetchMessages = async (conversationId: number, limit = 50, startTime?: string) : Promise<Message[]> => {
    const response = await axios.get(`/message/conversation/${conversationId}?limit=${limit}${startTime !== undefined ? "&startTime=" + startTime : ""}`);
    return response.data as Message[];
};

export const getConversationForCustomer = async (customerId: number) : Promise<Conversation> => {
    const response = await axios.get(`/conversation/customer/${customerId}`);
    return response.data as Conversation;
};

export const getConversationsOfRepresentative = async (representativeId: number) : Promise<Conversation[]> => {
    const response = await axios.get(`/conversaion/representative/${representativeId}`);
    return response.data as Conversation[];
};