import {
    AppBar,
    Box,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import { useContext, useState } from "react";
import { UserContext } from "../../App";
import { useAuth } from "react-oidc-context";

interface HeaderProps {
    title: string;
    onBackButtonClick?: () => void;
    chatAvatar?: React.ReactNode;
    leftAction?: React.ReactNode;
}

const Header = ({
    title,
    onBackButtonClick,
    chatAvatar,
    leftAction,
}: HeaderProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const userContext = useContext(UserContext);
    const auth = useAuth();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        await auth.signoutRedirect();
        localStorage.clear();
        userContext.setUser(undefined);
    };

    return (
        <AppBar position="sticky" sx={{ marginBottom: 2 }}>
            <Toolbar disableGutters>
                {onBackButtonClick !== undefined && (
                    <IconButton onClick={onBackButtonClick}>
                        <ArrowBackIosNewIcon sx={{ color: "white" }} />
                    </IconButton>
                )}
                {chatAvatar ? (
                    <Box
                        flexGrow={1}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        ml={2}
                    >
                        {chatAvatar}
                        &nbsp;
                        <Typography>{title}</Typography>
                    </Box>
                ) : (
                    <Typography variant="h5" ml={2} flexGrow={1}>
                        {title}
                    </Typography>
                )}
                {leftAction ? (
                    leftAction
                ) : (
                    <>
                        <IconButton onClick={handleMenu}>
                            <AccountCircle sx={{ color: "white" }} />
                        </IconButton>
                        <Menu
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            anchorEl={anchorEl}
                        >
                            <MenuItem onClick={() => void handleLogout()}>
                                <LogoutIcon /> Se déconnecter
                            </MenuItem>
                        </Menu>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
