import { Box, Card, Divider, Typography } from "@mui/material";

const RepresentativeHome = () => {
    return (
        <Box
            display="flex"
            flexGrow={1}
            flexDirection="column"
            alignItems="center"
        >
            <Card sx={{ width: 300, padding: 3 }} elevation={4}>
                <Typography variant="h6" fontWeight="bold">
                    Progrès des ventes
                </Typography>
                <Typography fontSize="small">
                    Avancements dans les ventes
                </Typography>
            </Card>
            <Divider
                orientation="horizontal"
                variant="middle"
                flexItem
                sx={{ marginTop: 2 }}
            />
        </Box>
    );
};

export default RepresentativeHome;
