import axios from "axios";
import { User } from "../interfaces/UserInterface";

export const getAllUsers = async () : Promise<User[]> => {
    const response = await axios.get("/user-info");
    return response.data as User[];
};

export const getUsersByRepresentative = async (representativeId : number) : Promise<User[]> => {
    const response = await axios.get(`/user-info/representative/${representativeId}`);
    return response.data as User[];
};

export const getUserByKeycloakId = async (keycloakId: string) : Promise<User> => {
    const response = await axios.get(`/user-info/keycloak-id/${keycloakId}`);
    return response.data as User;
};