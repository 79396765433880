import { Box } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import BottomNavigator from "./BottomNavigator";

const Frame = () => {
    const location = useLocation().pathname;

    return (
        <>
            <Box
                display="flex"
                flexGrow={1}
                bgcolor="background.default"
                maxWidth="100%"
                minHeight="100vh"
                paddingBottom="60px"
            >
                <Outlet />
            </Box>
            {location !== "/chat" && <BottomNavigator />}
        </>
    );
};

export default Frame;
