import {
    Alert,
    Avatar,
    Box,
    IconButton,
    Snackbar,
    styled,
    TextField,
    Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { User } from "../../../interfaces/UserInterface";
import Header from "../../core/Header";
import SendIcon from "@mui/icons-material/Send";
import { useContext, useEffect, useRef, useState } from "react";
import LocalPhone from "@mui/icons-material/LocalPhone";
import {
    fetchMessages,
    getConversationForCustomer,
    sendMessage as sendNewMessage,
} from "../../../api/chat";
import {
    Conversation,
    CreateMessageDTO,
    Message,
} from "../../../interfaces/ChatInterface";
import { OnlineContext, UserContext } from "../../../App";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";

const messageStyles = {
    fromSelf: {
        backgroundColor: "primary.main",
        color: "white",
        alignSelf: "flex-end",
    },
    fromOther: {
        backgroundColor: "lightgray",
        alignSelf: "flex-start",
    },
};

const MessageContainer = styled(Box)({
    maxWidth: "70%",
    textWrap: "wrap",
    borderRadius: 10,
    padding: 6,
    margin: 10,
    wordBreak: "break-word",
});

const Chat = () => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [newMessage, setNewMessage] = useState("");
    const location = useLocation();
    const [recipient, _] = useState<User | undefined>(location.state?.customer);
    const [conversation, setConversation] = useState<Conversation>();
    const [connection, setConnection] = useState<HubConnection>();
    const [error, setError] = useState(false);
    const [scroll, setScroll] = useState(true);

    const navigate = useNavigate();
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const observerTarget = useRef<HTMLElement>(null);

    const isOnline = useContext(OnlineContext);
    const user = useContext(UserContext).user;

    useEffect(() => {
        scroll && scrollToBottom();
        setScroll(true);
    }, [messages]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (
                    entries[0].isIntersecting &&
                    conversation !== undefined &&
                    messages.length > 0
                ) {
                    setScroll(false);
                    refreshMessages(conversation, messages[0].timeSent);
                }
            },
            { threshold: 1 }
        );

        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }

        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [observerTarget, messages]);

    useEffect(() => {
        if (isOnline) {
            if (recipient) {
                getConversationForCustomer(recipient.id)
                    .then((conv) => {
                        setConversation(conv);
                        refreshMessages(conv);
                    })
                    .catch(() => {});
            } else if (user !== undefined) {
                getConversationForCustomer(user.id)
                    .then((conv) => {
                        setConversation(conv);
                        refreshMessages(conv);
                    })
                    .catch(() => {});
            }

            let hubConnection = new HubConnectionBuilder()
                .withUrl(
                    (process.env.REACT_APP_API_URL as string) +
                        `/chat-hub?userId=${user?.id}`
                )
                .withAutomaticReconnect()
                .build();

            hubConnection
                .start()
                .then(() => console.log("Connected to SignalR hub"))
                .catch((err) => console.error("Error connecting to hub:", err));

            hubConnection.off("NewMessage");
            hubConnection.on("NewMessage", (message: Message) => {
                if (
                    recipient === undefined ||
                    recipient.id === message.fkSender
                ) {
                    setMessages((m) => [...m, message]);
                }
            });

            setConnection(hubConnection);
        } else {
            navigate("/");
        }
    }, []);

    const refreshMessages = (
        conversation: Conversation,
        startTime?: string
    ) => {
        if (isOnline) {
            fetchMessages(conversation.id, 20, startTime)
                .then((m) => {
                    m.length > 0 &&
                        m[0].id !== messages[0]?.id &&
                        setMessages(startTime ? [...m, ...messages] : m);
                })
                .catch(() => setMessages([]));
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView();
    };

    const sendMessage = async () => {
        if (
            user !== undefined &&
            conversation !== undefined &&
            connection !== undefined &&
            isOnline &&
            newMessage.trim() !== ""
        ) {
            const message: CreateMessageDTO = {
                fkConversation: conversation.id,
                fkSender: user.id,
                text: newMessage.trim(),
            };

            try {
                await connection.invoke(
                    "SendMessage",
                    recipient?.id ?? conversation.fkRepresentative,
                    message
                );
                setMessages([
                    ...messages,
                    {
                        id: -1,
                        fkConversation: conversation.id,
                        fkSender: user.id,
                        text: newMessage,
                        timeSent: new Date(Date.now()).toLocaleDateString(),
                    },
                ]);
                setNewMessage("");
                await sendNewMessage(message);
            } catch {
                setError(true);
            }
        } else setError(true);
    };

    const handleMessageChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        event.target.value.length <= 128 && setNewMessage(event.target.value);
    };

    const handleEnter = (e: React.KeyboardEvent) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            void sendMessage();
        }
    };

    return (
        <>
            <Snackbar
                open={error}
                autoHideDuration={6000}
                onClose={() => setError(false)}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                sx={{ marginTop: 6 }}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={() => setError(false)}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    Une erreur est survenue.
                </Alert>
            </Snackbar>
            <Box display="flex" flexGrow={1} flexDirection="column">
                <Header
                    title={
                        recipient
                            ? `${recipient.firstName} ${recipient.lastName}`
                            : "Représentant"
                    }
                    chatAvatar={
                        recipient ? (
                            <Avatar>{recipient.firstName[0]}</Avatar>
                        ) : undefined
                    }
                    leftAction={
                        recipient ? undefined : (
                            <IconButton>
                                <LocalPhone sx={{ color: "white" }} />
                            </IconButton>
                        )
                    }
                    onBackButtonClick={() =>
                        navigate(recipient ? "/customer-info" : "/", {
                            state: { customer: recipient },
                        })
                    }
                />
                <Box
                    display="flex"
                    width="100%"
                    height="100%"
                    flexDirection="column"
                    bottom={120}
                    paddingTop={25}
                    position="absolute"
                    sx={{ overflowY: "scroll" }}
                >
                    {messages.map((message, index) => (
                        <MessageContainer
                            key={index}
                            sx={
                                message.fkSender === user?.id
                                    ? messageStyles.fromSelf
                                    : messageStyles.fromOther
                            }
                            ref={index === 0 ? observerTarget : null}
                        >
                            <Typography>{message.text}</Typography>
                        </MessageContainer>
                    ))}
                    <span ref={messagesEndRef} />
                </Box>
                <Box position="fixed" bottom={0} width="100%" padding={2}>
                    <TextField
                        fullWidth
                        placeholder="Envoyer un message..."
                        multiline
                        maxRows={5}
                        value={newMessage}
                        onKeyDown={handleEnter}
                        onChange={handleMessageChange}
                        sx={{ borderRadius: 30 }}
                        InputProps={{
                            endAdornment: (
                                <>
                                    <Typography>
                                        {newMessage.length}/128
                                    </Typography>
                                    <IconButton
                                        onClick={() => void sendMessage()}
                                        disabled={!isOnline}
                                    >
                                        <SendIcon color="primary" />
                                    </IconButton>
                                </>
                            ),
                        }}
                    />
                </Box>
            </Box>
        </>
    );
};

export default Chat;
