import {
    Alert,
    Box,
    CircularProgress,
    CssBaseline,
    Snackbar,
    ThemeProvider,
} from "@mui/material";
import { theme } from "./theme";
import axios from "axios";
import AppRoutes from "./routes";
import { createContext, ReactNode, useEffect, useState } from "react";
import "./App.css";
import { User } from "./interfaces/UserInterface";
import { useAuth } from "react-oidc-context";
import { UserContext as IUserContext } from "./interfaces/UserInterface";

export const UserContext = createContext<IUserContext>({
    user: undefined,
    setUser: (_user: User | undefined) => {},
    isRepresentative: false,
    setIsRepresentative: (_representative: boolean) => {},
});

export const OnlineContext = createContext<boolean>(true);

const App = () => {
    const [user, setUser] = useState<User | undefined>(() => {
        const savedUserData = localStorage.getItem("userData");
        return savedUserData ? JSON.parse(savedUserData) as User : undefined;
    });
    const [isRepresentative, setIsRepresentative] = useState(
        user !== undefined && user.id === user.fkRepresentative
    );
    const [offline, setOffline] = useState(!navigator.onLine);
    const [backOnline, setBackOnline] = useState(false);

    useEffect(() => {
        if (user !== undefined)
            localStorage.setItem("userData", JSON.stringify(user));
    }, [user]);

    window.addEventListener("offline", () => {
        setOffline(true);
        setBackOnline(false);
    });

    window.addEventListener("online", () => {
        setOffline(false);
        setBackOnline(true);
    });

    const handleClose = () => {
        setOffline(false);
        setBackOnline(false);
    };

    axios.defaults.baseURL = process.env.REACT_APP_API_URL as string;

    return (
        <UserContext.Provider
            value={{
                user: user,
                setUser: setUser,
                isRepresentative: isRepresentative,
                setIsRepresentative: setIsRepresentative,
            }}
        >
            <OnlineContext.Provider value={!offline}>
                <AuthHandler>
                    <ThemeProvider theme={theme}>
                        <Snackbar
                            open={offline}
                            autoHideDuration={6000}
                            onClose={handleClose}
                            sx={{ position: "absolute", bottom: "90%" }}
                        >
                            <Alert
                                elevation={6}
                                variant="filled"
                                onClose={handleClose}
                                severity="warning"
                                sx={{ width: "100%" }}
                            >
                                Vous êtes hors-ligne.
                            </Alert>
                        </Snackbar>
                        <Snackbar
                            open={backOnline}
                            autoHideDuration={6000}
                            onClose={handleClose}
                            sx={{ position: "absolute", bottom: "90%" }}
                        >
                            <Alert
                                elevation={6}
                                variant="filled"
                                onClose={handleClose}
                                severity="success"
                                sx={{ width: "100%" }}
                            >
                                De nouveau en ligne.
                            </Alert>
                        </Snackbar>
                        <AppRoutes />
                        <CssBaseline />
                    </ThemeProvider>
                </AuthHandler>
            </OnlineContext.Provider>
        </UserContext.Provider>
    );
};

interface AuthHandlerProps {
    children: ReactNode;
}

const AuthHandler: React.FC<AuthHandlerProps> = ({ children }) => {
    const auth = useAuth();

    useEffect(() => {
        if (!auth.isAuthenticated && !auth.isLoading) {
            void auth.signinRedirect();
        }
        if (auth.user) {
            axios.defaults.headers.common.Authorization =
                `Bearer ${auth.user.id_token}`;
        }
    }, [auth]);

    useEffect(() => {
        if (auth.user) {
            if (auth.user.expired) auth.startSilentRenew();
        }
    }, []);

    if (auth.isLoading) {
        return (
            <Box>
                <CircularProgress />
            </Box>
        );
    }

    if (!auth.isAuthenticated) {
        return null;
    }

    return <>{children}</>;
};

export default App;
