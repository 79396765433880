import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useContext, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { Navigate } from "react-router-dom";
import { getUserByKeycloakId } from "../../api/user";
import { UserContext } from "../../App";
import { JwtRoles } from "../../interfaces/UserInterface";

const SignInCallback = () => {
    const auth = useAuth();
    const userContext = useContext(UserContext);

    useEffect(() => {
        if (auth.user) {
            const decodedJwt = jwtDecode<JwtRoles>(auth.user.access_token);

            userContext.setIsRepresentative(
                decodedJwt.realm_access.roles.includes("representative")
            );

            axios.defaults.headers.common["Authorization"] =
                `Bearer ${auth.user.id_token}`;

            getUserByKeycloakId(decodedJwt.sub ?? "")
                .then((user) => userContext.setUser(user))
                .catch(() => {});
        }
    });

    return <Navigate to="/" />;
};

export default SignInCallback;
