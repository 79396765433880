import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Product } from "../../../../interfaces/ProductInterfaces";

const products: Product[] = [
    {
        name: "Ecopticide Agri",
        image: "/images/ecopticide-agri.png",
        details:
            "Ecopticide Agri est un biopesticide multifonction, fongicide, nématicide, insecticide et bactéricide. Il est formulé à partir d’huile de thym et d’huile d’arbre à thé pour répondre aux défis de la gestion des ravageurs et des maladies des cultures.",
    },
    {
        name: "Un autre produit",
        image: "https://www.shutterstock.com/image-photo/chlorpyrifos-broadspectrum-insecticide-used-on-600nw-2322174253.jpg",
        details: "Description du produit",
    },
];

const Products = () => {
    const navigate = useNavigate();

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={2}
            alignItems="center"
            marginTop="auto"
            marginBottom={2}
        >
            {products.map((product, index) => (
                <Card
                    key={index}
                    sx={{
                        width: {
                            lg: "40%",
                            xs: "85%",
                        },
                    }}
                    elevation={5}
                >
                    <CardActionArea
                        onClick={() =>
                            navigate("/product-details", {
                                state: { product: product },
                            })
                        }
                    >
                        <CardMedia
                            sx={{ height: { lg: 300, xs: 140 } }}
                            image={product.image}
                        />
                        <CardContent>
                            <Typography align="center" variant="h6">
                                {product.name}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            ))}
        </Box>
    );
};

export default Products;
