import { BrowserRouter, Route, Routes } from "react-router-dom";
import Frame from "./components/core/Frame";
import Home from "./components/pages/Home/Home";
import ProtectedRoute from "./components/core/ProtectedRoute";
import ProductDetails from "./components/pages/Home/Products/ProductDetails";
import Customers from "./components/pages/Customers/Customers";
import CustomerInfo from "./components/pages/CustomerInfo/CustomerInfo";
import Chat from "./components/pages/Chat/Chat";
import SignInCallback from "./components/core/SignInCallback";

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Frame />}>
                    <Route
                        path="/"
                        element={
                            <ProtectedRoute>
                                <Home />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="/callback" element={<SignInCallback />} />
                    <Route
                        path="/product-details"
                        element={
                            <ProtectedRoute>
                                <ProductDetails />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/customers"
                        element={
                            <ProtectedRoute representativeOnly>
                                <Customers />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/customer-info"
                        element={
                            <ProtectedRoute representativeOnly>
                                <CustomerInfo />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/chat"
                        element={
                            <ProtectedRoute>
                                <Chat />
                            </ProtectedRoute>
                        }
                    />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
