import { Avatar, Box, Card, CardActionArea, CardHeader } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUsersByRepresentative } from "../../../api/user";
import { UserContext } from "../../../App";
import { User } from "../../../interfaces/UserInterface";
import Header from "../../core/Header";

const Customers = () => {
    const navigate = useNavigate();
    const [customers, setCustomers] = useState<User[]>([]);
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (user !== undefined) {
            getUsersByRepresentative(user.id)
                .then((data) => setCustomers(data))
                .catch(() => setCustomers([]));
        }
    }, []);

    return (
        <Box display="flex" flexGrow={1} flexDirection="column">
            <Header title="Clients" />
            <Box
                display="flex"
                flexDirection="column"
                gap={2}
                alignItems="center"
                marginTop="auto"
                marginBottom="auto"
            >
                {customers.map((customer, index) => (
                    <Card
                        key={index}
                        sx={{
                            width: {
                                lg: "40%",
                                xs: "85%",
                            },
                        }}
                        elevation={5}
                    >
                        <CardActionArea
                            onClick={() =>
                                navigate("/customer-info", {
                                    state: { customer: customer },
                                })
                            }
                        >
                            <CardHeader
                                avatar={
                                    <Avatar
                                        sx={{
                                            width: 70,
                                            height: 70,
                                            backgroundColor: "primary.main",
                                        }}
                                    >
                                        {customer.firstName[0]}
                                    </Avatar>
                                }
                                title={`${customer.firstName} ${customer.lastName}`}
                                subheader={customer.position}
                            />
                        </CardActionArea>
                    </Card>
                ))}
            </Box>
        </Box>
    );
};

export default Customers;
