import { Box, Avatar, Typography, styled, Button, Link } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { User } from "../../../interfaces/UserInterface";
import Header from "../../core/Header";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import SmsIcon from "@mui/icons-material/Sms";

const InfoContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    margin: 8,
    [theme.breakpoints.up("lg")]: {
        width: "30%",
        marginLeft: "auto",
        marginRight: "auto",
    },
}));

const buttonStyle = {
    width: 300,
    ml: "auto",
    mr: "auto",
    mt: 5,
};

const CustomerInfo = () => {
    const { customer }: { customer: User; } = useLocation().state as { customer : User; };
    const navigate = useNavigate();

    return (
        <Box display="flex" flexGrow={1} flexDirection="column">
            <Header
                title="Infos du client"
                onBackButtonClick={() => navigate("/customers")}
            />
            <Avatar
                sx={{ height: 128, width: 128, ml: "auto", mr: "auto", mb: 2 }}
            >
                {customer.firstName}
            </Avatar>
            <Typography align="center">{`${customer.firstName} ${customer.lastName}`}</Typography>

            <InfoContainer>
                <Typography>Position du client:</Typography>
                <Typography>{customer.position}</Typography>
            </InfoContainer>
            <InfoContainer>
                <Typography>Courriel:</Typography>
                <Typography>{customer.email}</Typography>
            </InfoContainer>
            <InfoContainer>
                <Typography>Téléphone:</Typography>
                <Typography>{customer.phone}</Typography>
            </InfoContainer>

            <Button
                component={Link}
                href={`tel:${customer.phone}`}
                variant="contained"
                sx={buttonStyle}
            >
                <LocalPhoneIcon /> &nbsp; Appeler
            </Button>
            <Button
                variant="contained"
                sx={buttonStyle}
                onClick={() =>
                    navigate("/chat", { state: { customer: customer } })
                }
            >
                <SmsIcon /> &nbsp; Message
            </Button>
        </Box>
    );
};

export default CustomerInfo;
